import IProductCategory from '../../Model/Interface/IProductCategory';
import IService from '../Interface/IService';

const axios = require('axios');

export class CategoryService implements IService {
    
    public static async getProductCategories() {
        let response = await this.retry(axios.get(`${process.env.REACT_APP_API_URL}/v1/category/list/`), 0);
        return response.data as IProductCategory[]
    }

    public static async postProductCategories(category: IProductCategory ) {
        let response = await this.retry(axios.post(`${process.env.REACT_APP_API_URL}/v1/category/create`,{...category}), 0);
        return response.data as IProductCategory
    }

    public static async deleteProductCategories(category: string ) {
        let response = await this.retry(axios.delete(`${process.env.REACT_APP_API_URL}/v1/category/delete/${category}`), 0);
        return response.data
    }

    public static async updateProductCategory(category: IProductCategory ) {
        let response = await this.retry(axios.post(`${process.env.REACT_APP_API_URL}/v1/category/update`,{...category}), 0);
        return response.data as IProductCategory
    }

    private static async retry(asyncMethod: Promise<any>, count: number) {
        if (count >= 5) return null;
        try {
            var response = await asyncMethod;
            if (response.status != 200 && count <= 5) {
                throw response.err;
            }
            return response;
        }
        catch (err) {
            if (count <= 5) {
                console.info("retry ->", response);
                console.info("retry err ->", count);
                setTimeout(async () => await this.retry(asyncMethod, count + 1), 3000);
            }
            return err;
        }

    }
}

export default CategoryService;