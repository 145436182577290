export const decimalValidation = new RegExp(
    '^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$'
)

export const emailValidation = new RegExp(
    '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$'
)

export const password = new RegExp(
    '^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$'
)

export function validateCPF(cpf: string): boolean {
    // Remove pontos e traços
    cpf = cpf.replace(/[^\d]+/g,'');
 
    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }
 
    // Verifica se todos os dígitos são iguais
    if (/(\d)\1{10}/.test(cpf)) {
        return false;
    }
 
    // Calcula os dígitos verificadores
    let firstDigit = 0;
    let secondDigit = 0;
    for (let i = 0; i < 9; i++) {
        firstDigit += parseInt(cpf[i]) * (10 - i);
        secondDigit += parseInt(cpf[i]) * (11 - i);
    }
    firstDigit = (firstDigit * 10) % 11;
    if (firstDigit === 10) {
        firstDigit = 0;
    }
    secondDigit += firstDigit * 2;
    secondDigit = (secondDigit * 10) % 11;
    if (secondDigit === 10) {
        secondDigit = 0;
    }
 
    // Verifica se os dígitos verificadores estão corretos
    return cpf[9] === firstDigit.toString() && cpf[10] === secondDigit.toString();
}