import React, { createContext, useContext, useEffect, useState } from 'react';
import ILogin from '../Model/Interface/ILogin';
import ILoginSecurity from '../Model/Interface/ILoginSecurity';
import SecurityService from '../Service/Implementation/SecurityService';
import { StorageService } from '../Service/Implementation/StorageService';

interface AuthContextData {
    signed: boolean;
    login(userData:ILogin | null): Promise<void>;
    token:ILoginSecurity  | null;
    checkLogin():boolean;
    logout():Promise<void>;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
    
    const [loginSec, setLoginSec] = useState<ILoginSecurity | null>(null);

    const [user, setUser] = useState<ILogin | null>(null);
    const [isSigned, setIsSigned] = useState(false);

    function checkLogin(){
        let storageToken =  localStorage.getItem("authInfo")
        if(storageToken) {
            let parsedObject = JSON.parse(storageToken) as ILoginSecurity
            if(parsedObject) setLoginSec(parsedObject)
            let now = (new Date(Date.now()))
            //EPOCH Converter 
            //Equalize TimeSpan (C#) and TimeStamp (TS JS)
            let tokenTimer = ((now.getTime()/1000) - parsedObject.created*1000)/360
            if(tokenTimer < 60 ) {
                setIsSigned(true)
                return true;
            }
            
            localStorage.removeItem("authInfo")
            setLoginSec(null)
        }
        setIsSigned(false)
        return false;
    }
    
    useEffect(() =>{
        checkLogin()
    },[isSigned])

    async function login(userData:ILogin): Promise<void> {
        try{
            if (userData && !checkLogin()) {
                setUser(userData)
                let loginResponse = await SecurityService.login(userData)

                loginResponse.updated
                if(loginResponse)
                {
                    setLoginSec(loginResponse)
                    localStorage.removeItem("authInfo")
                    localStorage.setItem("authInfo",JSON.stringify(loginResponse))
                }
            }
        }
        catch(error){
            alert(`${error} - Failed`)
        }
    }
    async function logout():Promise<void>{
        localStorage.clear()
    }
    return (
        <AuthContext.Provider value={{ signed: isSigned, login:login, token:loginSec, checkLogin:checkLogin, logout:logout}}>
            {children}
        </AuthContext.Provider>
    )
}


export function useAuth() {
    const context = useContext(AuthContext);

    return context;
}