import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box, Accordion, AccordionDetails, AccordionSummary, alpha, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableContainer, TablePagination, Toolbar, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/Auth";
import IProductStock from "../Model/Interface/IProductStock";
import ProductStock from "../Model/ProductStock";
import { User } from "../Model/User";
import SecurityService from "../Service/Implementation/SecurityService";
import StockService from "../Service/Implementation/StockService";
import { ProductForm } from "../Component/App/ProductForm";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuid } from 'uuid'
import { titleStyled } from "../globalStyle";
import Loader from "../Component/App/Loader";
import { UserForm } from "../Component/App/UserForm";


interface Data {
    index: number,
    userName: string;
    secretPass: string;
    email: string;
    profileImage: string;
}

function createData(
    index: number,
    userName: string,
    secretPass: string,
    email: string,
    profileImage: string,

): Data {
    return {
        index,
        userName,
        secretPass,
        email,
        profileImage,
    };
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const mockCols: readonly HeadCell[] = [
    {
        id: 'index',
        numeric: true,
        disablePadding: true,
        label: '#',
    },
    {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
    },
    {
        id: 'email',
        label: 'Email',
        numeric: false,
        disablePadding: false,
    },
    {
        id: 'profileImage',
        label: 'Imagem',
        numeric: false,
        disablePadding: false,
    }
]

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {mockCols.map((mockCol) => (
                    <TableCell
                        key={mockCol.id}
                        align={'left'}
                        padding={mockCol.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === mockCol.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === mockCol.id}
                            direction={orderBy === mockCol.id ? order : 'asc'}
                            onClick={createSortHandler(mockCol.id)}
                        >
                            {mockCol.label}
                            {orderBy === mockCol.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function UserTable() {
    const { signed, checkLogin } = useAuth();
    const [users, setUsers] = useState<Array<User>>(new Array<User>)
    const [user, setUser] = useState<User | null>(null)
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('index');
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState<Data[]>([createData(1, '', '', '', '')]);
    const [expanded, setExpanded] = useState(true)

    async function handleDelete(event:React.MouseEvent){
        event.preventDefault()
        let delUsers = selected.map(n => users.find((user, pos) => n === pos+1))
        
        if(delUsers && delUsers.length > 0) {
            delUsers.forEach(delUser => {
              if(delUser){
                setUsers(users.filter(user => user.id != delUser.id))
                //todo: inativar usuário
              }
          })
        }
        else {
          alert('selecione um produto')
        }
        setSelected([0])
    }
  
    useEffect(() => {
        if (!signed && !checkLogin()) {
            window.open("/", '_self')
        }

        if (!users || users.length <= 0) {
            try {
                SecurityService.getUsers().then(result => setUsers(result))
            } catch {
                window.open("/", '_self')
            }
        }

        if (users) {
            setData(users.map((u: User, index: number) => { 
                return createData(
                ++index,
                u.email,
                '',
                u.email,
                '')
             }))
        }
       
    }, [users, signed])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = data;
            setSelected(data.map(d => d.index));
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        let user = users.find((u,pos) => pos+1 === index)
        if(user) setUser(user)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (index: number) => selected.indexOf(index) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    interface EnhancedTableToolbarProps {
        numSelected: number;
    }

    function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Produtos
                    </Typography>
                )}
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterAltIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    }


    return (
        users.length <= 0 ? 
        <div className="mx-auto align-middle"><Loader/></div> :
        <Box sx={{ width: '89%' }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={titleStyled}>Adicionar / Modificar</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {user && <UserForm {...{ data: user, isUpdate: true }} />}
                        {!user && <UserForm {...{
                            data: {
                                id: uuid(),
                                email: '',
                                password: '',
                                oAuthIdentities: "{}",
                                accountType: "worker",
                                name: '',
                                person: {
                                    id: "",
                                    name: "",
                                    gender: "",
                                    age: 0,
                                    cpf: "",
                                },
                            }, isUpdate: false
                        }} />}
                </AccordionDetails>
            </Accordion>
            <Paper sx={{ width: 'auto', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={users.length}
                        />
                        {
                            users.length <= 0
                                ? <TableBody>
                                </TableBody>
                                : <TableBody>
                                    {stableSort<Data>(data, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.index);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.index)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.userName}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.index}
                                                    </TableCell>
                                                    <TableCell align="left">{row.userName}</TableCell>
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">{row.profileImage}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>

    )
}
