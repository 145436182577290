import React, { useEffect, useState } from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Box, TableSortLabel, FormControlLabel, Switch, TablePagination, alpha, IconButton, Toolbar, Tooltip, Typography, AccordionDetails, Accordion, AccordionSummary } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { useAuth } from "../context/Auth";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { titleStyled } from "../globalStyle";
import IProductCategory from "../Model/Interface/IProductCategory";
import { StockService, CategoryService } from "../Service/index";
import IProduct from "../Model/Interface/IProduct";
import ProductModel from "../Model/ProductModel";
import { ProductForm } from "../Component/App/ProductForm";
import Loader from "../Component/App/Loader";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  index: number,
  price: number,
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  description: string;
  protein: number;
  options: number | 0
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  index: number;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {mockCols.map((mockCol) => (
          <TableCell
            key={mockCol.id}
            align={'left'}
            padding={mockCol.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === mockCol.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === mockCol.id}
              direction={orderBy === mockCol.id ? order : 'asc'}
              onClick={createSortHandler(mockCol.id)}
            >
              {mockCol.label}
              {orderBy === mockCol.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const mockCols: readonly HeadCell[] = [
  {
    id: 'index',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'description',
    label: 'Descrição',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'options',
    label: 'Opções',
    numeric: true,
    disablePadding: false,
  }
]

export default function ProductTable() {
  const { signed, checkLogin } = useAuth();
  const [products, setProducts] = useState<Array<IProduct>>(new Array<IProduct>)
  const [product, setProduct] = useState<IProduct>(ProductModel.newProduct())
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('index');
  const [selected, setSelected] = useState<number>(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState<Data[]>([{
    index: 0,
    price: 0,
    name: "",
    description: "",
    options:0
  } as Data]);
  const [expanded, setExpanded] = useState(false)
  const isSelected = (pos: number) => pos === selected;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    window.addEventListener('product-update', (event) => {
      setProducts(new Array<IProduct>())
    });
    setIsLoading(true)
    console.log("useEffect called");

    if (!signed && !checkLogin()) {
      console.log('should redirect')
      window.open("/", '_self')
    }
    if (product.name != "") {
      setProduct(product)
    }

    if (!products || products.length < 1) {
      try {
        setTimeout(
          () => {
            StockService.getStockProducts().then((value) => {
              let dataProd = value.map((prod, index) => {
                return ({
                  index: ++index,
                  price: prod.default_Price,
                  name: prod.name,
                  description: prod.big_Description,
                  options: prod.options.length || 0
                } as Data)
              })
              setData(dataProd)
              setProducts(value)
            })
          },
          10000)
      } catch (error) {
        alert(error)
      }
      setIsLoading(false)
      console.log(products)
    }
  }, [products, signed, data, isLoading])

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;
    const { index } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 && product
          ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {product.name}
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Produtos
            </Typography>
          )}
      </Toolbar>
    );
  }

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleClick(event: React.MouseEvent<unknown>, index: number) {
    console.info("handleClick product", product);
    setIsLoading(true)
    if (!isSelected(index)) {
      let newProd = ProductModel.newProduct()
      newProd = products.find((prod, position) => position + 1 == index) || ProductModel.newProduct()
      setProduct(newProd)
      setSelected(index);

    } else {
      event.preventDefault()
      setProduct(ProductModel.newProduct())
      setSelected(-1)
    }
    setIsLoading(false)
    console.info("isSelected(index)", isSelected(index));
    console.info("index", index);
    console.info("handleClick product", product);
  };

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  };

  return (

    <Box sx={{ width: '89%' }}>
      {
        <div>
          <Accordion expanded={expanded ||( selected > 0 && product.name != '')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={titleStyled}>Adicionar / Modificar</Typography>
                </AccordionSummary>
                {(selected > 0 && product.name != '')
                  && <ProductForm {...{ item: product, isUpdate: true, }} />}

                {(selected < 1 || product == ProductModel.newProduct()) &&
                  <ProductForm {...{ item: ProductModel.newProduct(), isUpdate: false, }} />}

              </Accordion>
          {isLoading || products.length < 1 && <div className="mx-auto align-middle"><Loader /></div>}
          {!isLoading || products.length > 0 &&
            <div>
              
              
              <Paper sx={{ width: 'auto', mb: 2 }}>

                <EnhancedTableToolbar
                  numSelected={selected > 0 ? 1 : 0}
                  index={product ? products.indexOf(product) : 0} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                  >
                    {/* <EnhancedTableHead
                      numSelected={selected > 0 ? 1 : 0}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={products.length}
                    /> */}
                    {
                      <TableBody>
                        {stableSort<Data>(data, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const isItemSelected = isSelected(row.index);
                            const labelId = `enhanced-table-checkbox-${row.index}`;
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.index)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.index}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  {row.index}
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                                <TableCell align="left">{row.options}</TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (33) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={products.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          }
        </div>
      }

    </Box>
  )
}
