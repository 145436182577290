import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from '../Component/App/Sidebar';
import Institucional from '../pages/institucional/institucional';
import Login from '../pages/Login';

export function OtherRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Institucional />} />
        <Route path="admin" element={<Admin />}>
          <Route index element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default OtherRoutes;