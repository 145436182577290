import { CircularProgress } from "@mui/material";
import React, { ChangeEvent, FocusEvent, FocusEventHandler, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { button, buttonActive, buttonHover, logo, spanStyled } from "../globalStyle";
import logoHz from "../assets/images/logo/logo_horizontal.png";

//TODO: AUTH
//Auth Context
//Store this



export function Login() {

    const [buttonStyle, setButtonStyle] = useState<{}>(button);

    const handleMouseEnter = () => {
        setButtonStyle(buttonHover)
    };

    const handleMouseLeave = () => {
        setButtonStyle(button)
    };

    const handleMouseClick = () => {
        setButtonStyle(buttonActive)
    };


    const { signed, login } = useAuth();
    const [user, setUser] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false)
    console.log(signed)

    async function handleLogin() {
        await login({
            login: user,
            password: password
        })
    }

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        let targetData = (event.target as HTMLInputElement)
        if (event.target.id == "user") setUser(targetData.value)
        if (event.target.id == "password") setPassword(targetData.value)
        console.log(user)
        console.log(password)
    }

    function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
        let targetData = (event.target as HTMLInputElement)
        if (event.target.id == "user") setUser(targetData.value)
        if (event.target.id == "password") setPassword(targetData.value)
        console.log(user)
        console.log(password)
    }

    return (
        <div className="grid grid-col-3">
            {!loading &&
                <>
                    <div className="mx-auto col-start-2 my-4">
                        <Link to="/" className="flex items-center" style={{ textDecoration: "none" }} >
                            <img src={logoHz} style={logo(window.screen.width)} />
                        </Link>
                    </div>
                    <div className="mx-auto col-start-2 my-4">
                        <label htmlFor="user" className="pr-5" style={spanStyled}>Usuário: </label>
                        <br />
                        <input onChange={handleOnChange} onBlur={handleOnBlur} id="user" type="text" name="user" className="rounded-full px-4 py-3 " />
                    </div><div className="mx-auto col-start-2 my-2">
                        <label htmlFor="password" className="pr-5" style={spanStyled}>Senha: </label>
                        <br />
                        <input onChange={handleOnChange} onBlur={handleOnBlur} id="password" type="password" name="password" className="px4 py-3 rounded-full" />
                    </div><div className="col-start-2 my-4 mx-auto">
                        <button
                            onClick={() => handleLogin()}
                            className=""
                            style={buttonStyle}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onMouseDown={handleMouseClick}
                        >
                            Login
                        </button>
                    </div></>}
            {loading &&
                <><CircularProgress /></>}

        </div>
    )
}
export default Login