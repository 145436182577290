import styled from 'styled-components';

import footer_bg from '../../assets/images/institucional/footer_background.jpg'

export const Container = styled.div`
    background: url(${footer_bg});
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 100px;
    font-family: 'Quattrocento', serif;

    img{
        width: 160px;
        border-radius: 10px;
    }

    p, a{
        font-family: 'Lora', serif;
    }

    @media (max-width: 720px){
        flex-wrap: wrap;
        gap: 20px;
    }
`;

export const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(74, 34, 26);
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 

    &.link_download{
        gap: 30px;
        align-items: center;

        .link{
            margin-top: 20px;
        }
    }

    @media (max-width: 720px){
        flex-wrap: wrap;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 720px){
        flex-wrap: wrap;
    }
`;

export const Adress = styled.div`
    width: 600px;
    display: flex;
    align-items: center;
    gap: 70px;
    
    p{
        color: #764c24 !important;
        
        &.copy{
            color: #636363 !important;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 100;
        }
    }

    @media (max-width: 720px){
        flex-direction: column;
        text-align: center;
    }
`;