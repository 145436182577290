import React, { useState, useEffect } from "react";
import { button, buttonActive, buttonHover, cardDiv, icon, spanStyledBySize, titleStyled } from "../../globalStyle";
import IProductCategory from "../../Model/Interface/IProductCategory";
import { v4 as uuid } from 'uuid';
import { CategoryService, StorageService } from "../../Service";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Checkbox, Box, TableSortLabel, FormControlLabel, Switch, TablePagination, alpha, IconButton, Toolbar, Tooltip, Typography, AccordionDetails, Accordion, AccordionSummary } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAuth } from "../../context/Auth";
import IImageUpload from "../../Model/Interface/IImageUpload";
import logo from "../../assets/images/EmUso/logo.png";
import IImage from "../../Model/Interface/IImage";

interface Data {
    index: number,
    name: string,
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    index: number;
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function createData(
    index: number,
    name: string,
): Data {
    return {
        index,
        name
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const mockCols: readonly HeadCell[] = [
    {
        id: 'index',
        numeric: true,
        disablePadding: true,
        label: '#',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
    }
]

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {mockCols.map((mockCol) => (
                    <TableCell
                        key={mockCol.id}
                        align={'left'}
                        padding={mockCol.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === mockCol.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === mockCol.id}
                            direction={orderBy === mockCol.id ? order : 'asc'}
                            onClick={createSortHandler(mockCol.id)}
                        >
                            {mockCol.label}
                            {orderBy === mockCol.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function CategoryForm(props: {
    item: IProductCategory,
    categorieList: Array<IProductCategory>,
    isUpdate: boolean
}) {
    //TODO: FILE UPLOAD AND STOCK QUANTITY
    const { signed, checkLogin } = useAuth();
    const [category, setCategory] = useState<IProductCategory>(props.item)
    const [categories, setCategories] = useState<IProductCategory[] | null>(null)
    const [name, setName] = useState<string>('')
    const [expanded, setExpanded] = useState(true)
    const [buttonStyle, setButtonStyle] = useState<{}>(button);
    const [selected, setSelected] = useState<number>(-1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('index');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState<Data[]>([createData(1, '')]);
    const isSelected = (pos: number) => selected == pos;
    const [fileUrl, setFileUrl] = useState(logo)
    const [image, setImage] = useState<IImageUpload>(props.item.image as IImageUpload)
    const [page, setPage] = useState(0);
    const emptyRows = categories && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0

    useEffect(() => {
        if (!signed && !checkLogin()) {
            console.log('should redirect')
            window.open("/", '_self')
        }

        if (!categories) {
            try {
                CategoryService.getProductCategories().then((result) => setCategories(result))
            } catch (error) {
                alert(error)
            }
        }

        if (categories) {
            setData(categories.map((cat, index) => { return createData(index + 1, cat.name) }))
        }

    }, [categories])

    function handleClick(event: React.MouseEvent<unknown>, index: number) {
        event.preventDefault()
        console.info("handle click", index)
        if (isSelected(index)) {
            setSelected(-1)
            setCategory({
                name: "",
                id: uuid(),
                image: {
                    name: "",
                    id: uuid(),
                    objectId: uuid(),
                    created: 0,
                    ownerId: uuid(),
                    updated: 0,
                    entityId: uuid(),
                    url: fileUrl
                },
                objectId: uuid(),
                created: 0,
                ownerId: uuid(),
                updated: 0,
                url_image: ""
            })
        } else {
            if (categories) {
                const categorySelected = categories.find((category, pos) => (index - 1 == pos))
                if (categorySelected) {
                    let newSelected = selected
                    newSelected = index
                    console.info("handle file", categorySelected.url_image)
                    setFileUrl(categorySelected.url_image)
                    setSelected(newSelected);
                    setCategory(categorySelected)
                }
            }
        }
    };

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
    };

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleRequestSort(
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) {
        event.preventDefault()
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function handleSetName(event: any) {
        event.preventDefault()
        setName(event.target.value)
        category.name = event.target.value
        setCategory(category)
    }

    async function handleOnSubmit(event: any) {
        if (!category || name == '') {
            alert('Preencha todos os campos');
        }
        else if (!image.file) {
            alert('Insira uma imagem');
        }
        else {
            try {
                let newCategory = await addImage(image, image.file)
                if (newCategory) {
                    let result = await CategoryService.postProductCategories(newCategory);
                    if (result) {
                        setCategory(result)
                        alert('adicionado com sucesso')
                    } else {
                        alert(JSON.stringify("Falha ao adicionar"));
                    }
                }
            } catch (error) {
                alert(JSON.stringify(error));
            }
        }
        await CategoryService.getProductCategories().then((allCategories) => setCategories(allCategories))
    }

    async function handleOnUpdate(event: any) {
        if (!category) {
            alert('Preencha todos os campos');
        }
        else if (!image.file) {
            alert('Insira uma imagem');
        }
        else {
            try {
                let categoryWithNewImage = await addImage(image as IImage, image.file)
                let result = await CategoryService.updateProductCategory(categoryWithNewImage);
                if (result) {
                    setCategory(result)
                    if (result.url_image) setFileUrl(result.url_image)
                    alert('Atualizado com sucesso')
                } else {
                    alert(JSON.stringify('Falha ao Atualizar'));
                }

            } catch (error) {
                alert("contate o administrador");
            }
        }
        await CategoryService.getProductCategories().then((allCategories) => setCategories(allCategories))
    }

    async function handleDelete(event: any) {
        event.preventDefault()
        if (category) {
            try {
                let delResult = await CategoryService.deleteProductCategories(category.id);

                if (delResult) {
                    deleteImage()
                    await CategoryService.getProductCategories().then((result) => setCategories(result))
                    let blankCategory = {
                        name: "",
                        id: uuid(),
                        image: {
                            name: "",
                            id: uuid(),
                            objectId: uuid(),
                            created: 0,
                            ownerId: uuid(),
                            updated: 0,
                            entityId: uuid(),
                            url: ""
                        },
                        objectId: uuid(),
                        created: 0,
                        ownerId: uuid(),
                        updated: 0,
                        url_image: "",
                    }

                    setCategory(blankCategory)
                    setImage(blankCategory.image as IImageUpload)
                    if (categories) {
                        let categoriesNew = categories.filter(c => c.id != category.id)
                        setCategories(categoriesNew)
                    }
                    setSelected(-1)
                    setPage(0)
                    setFileUrl(logo)
                }

            } catch (error) {
                alert(error)
            }
        }
    }

    async function addImage(image: IImage, file: File) {
        let newCategory = { ...category }
        let addedImage = { ...image } as IImageUpload
        try {
            let imageAdded = await StorageService.addImage(file, category.objectId) as IImageUpload

            if (imageAdded) {

                if (!imageAdded.url) imageAdded.url = URL.createObjectURL(file)

                addedImage.file = file
                addedImage.url = imageAdded.url
                newCategory.image = imageAdded
                newCategory.url_image = imageAdded.url

                setImage(addedImage)
                setCategory(newCategory)
                setFileUrl(imageAdded.url)

            }

        } catch {
            alert("Imagem não adicionada");
        }
        return newCategory
    }

    async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        let newCategory = { ...category }

        if (e && e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size / 1000 > 1000) alert('imagem muito grande')
            else {
                let image = {
                    name: newCategory.id,
                    id: `${newCategory.id}`,
                    url: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0]
                } as IImageUpload;
                newCategory.image = image
                newCategory.url_image = image.url
                setFileUrl(image.url)
                setImage(image)
            }
        }
        else {
            setFileUrl(logo)
        }
        setCategory(newCategory)

    }

    async function deleteImage() {
        setFileUrl(logo)
        let fileName = category.url_image.split('/')[category.url_image.split('/').length - 1]
        let result = await StorageService.deleteImage(fileName)
        if (result) {
            let newCategory = { ...category }
            newCategory.image = {
                name: "",
                id: uuid(),
                objectId: uuid(),
                created: 0,
                ownerId: uuid(),
                updated: 0,
                entityId: uuid(),
                url: ""
            }
            setImage(newCategory.image as IImageUpload)
            setCategory(newCategory)
        }
        return result;
    }

    const renderEnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const { numSelected } = props;
        const { index } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Categorias
                    </Typography>
                )}
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteForeverIcon onClick={handleDelete} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterAltIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    }

    const renderForm = (categoryDetail: IProductCategory) => {
        return (
            <div className="container" style={cardDiv}>
                <div className="grid grid-cols-4 gap-1 w-7/12 mx-auto" >
                    <div className="col-span-4 mx-auto w-full mt-5">
                        <label htmlFor="productName" style={spanStyledBySize(window.screen.width)}>
                            Nome da Categoria
                            <input
                                type="text"
                                id="productName"
                                placeholder={"Nome da Categoria"}
                                aria-describedby="Product Name"
                                onChange={handleSetName}
                                onBlur={handleSetName}
                                className="my-5 w-full border-2 rounded-lg text-center"
                                value={categoryDetail.name || ""}
                            />
                        </label>
                    </div>
                    <div className="mx-auto col-span-4">
                        <div>
                            {(categoryDetail.url_image)
                                || fileUrl
                                ? <div>
                                    <button onClick={deleteImage}> <DeleteForeverIcon style={icon(window.screen.width)} /> </button>
                                </div>
                                : <div></div>}
                        </div>
                        <div>
                            {(categoryDetail.url_image) && categoryDetail.url_image.length > 10
                                ? <img src={categoryDetail.url_image} width="250px" height="250px" />
                                : <img src={fileUrl} width="250px" height="250px" />}
                        </div>
                    </div>
                    {image &&
                        <div className="col-span-4">
                            <label htmlFor="file" style={spanStyledBySize(window.screen.width)}>
                                Imagem
                            </label>
                            <input
                                type="file"
                                id="file"
                                placeholder={"..."}
                                aria-describedby="arquivo"
                                onChange={handleFileChange}
                                className="mx-4 my-3 py-2 w-full sm:w-11/12 border-2 rounded-lg text-center"
                            />
                        </div>
                    }
                    <div className="col-span-4 mx-auto mb-9">
                        {selected <= 0
                            ? <button
                                style={buttonStyle}
                                onMouseEnter={() => setButtonStyle(buttonHover)}
                                onMouseLeave={() => setButtonStyle(button)}
                                onMouseDown={() => setButtonStyle(buttonActive)}
                                onMouseUp={() => setButtonStyle(buttonHover)}
                                onClick={(e) => handleOnSubmit(e)}
                            >
                                Cadastrar
                            </button>
                            : <button
                                style={buttonStyle}
                                onMouseEnter={() => setButtonStyle(buttonHover)}
                                onMouseLeave={() => setButtonStyle(button)}
                                onMouseDown={() => setButtonStyle(buttonActive)}
                                onMouseUp={() => setButtonStyle(buttonHover)}
                                onClick={(e) => handleOnUpdate(e)}
                            >
                                Update
                            </button>}
                    </div>
                </div>
            </div>
        )

    }

    return (
        <Box sx={{ width: '89%' }}>
            <Accordion expanded={expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={titleStyled}>
                        Adicionar
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {category && (fileUrl || category.image) && renderForm(category)}
                </AccordionDetails>
            </Accordion>
            <Paper sx={{ width: 'auto', mb: 2 }}>
                {renderEnhancedTableToolbar({
                    numSelected: selected > 0 ? 1 : 0,
                    index: category && categories ? categories.indexOf(category) : 0
                })}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        {categories && categories.length <= 0 &&
                            <EnhancedTableHead
                                numSelected={selected > 0 ? 1 : 0}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={categories.length}
                            />}

                        {!categories || !data || categories.length <= 0
                            ? <TableBody>
                            </TableBody>
                            : <TableBody>
                                {stableSort<Data>(data, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.index);
                                        const labelId = `enhanced-ztable-checkbox-${row.index}`;
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.index)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={`${row.index + 1}-line-table`}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    key={`${row.index + 1}${name}-line-table`}
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.index}
                                                </TableCell>
                                                <TableCell
                                                    key={index + name}
                                                    align="left">
                                                    {row.name}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (33) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                    </Table>

                </TableContainer>
                {categories && data &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={categories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        defaultValue="Linhas Por Página"
                        aria-label="Linhas Por Página"
                    />
                }

            </Paper>
        </Box>
    )
}