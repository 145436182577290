import styled from 'styled-components';

import background from '../../assets/images/institucional/background.jpg';
import background2 from '../../assets/images/institucional/background2.jpg';
import background3 from '../../assets/images/institucional/background3.jpg';
import background4 from '../../assets/images/institucional/background4.jpg';

import separator from '../../assets/images/institucional/heading-separate.png';

export const Page = styled.div`

        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        font-family: 'Quattrocento', serif;
        
        h1{
            font-family: Lora, sans-serif;
            font-weight: bold;
            color: rgb(74, 34, 26);
            font-size: 50px;
            font-weight: 700;
        }
        h2{
            font-weight: bold;
            font-family: Lora, sans-serif;
            color: rgb(118, 76, 36);
            font-size: 20px;
            font-weight: 700;
        }
        p{
            color: rgb(70, 70, 70);
            font-size: 15px;
            font-weight: 700;
        }
        a{
            text-decoration: none;
        }
        .card_list{
            display: flex;

            img{
                width: 300px;
            }
            p{
                color: rgb(70, 70, 70);
                font-size: 15px !important;
                font-weight: 400;
            }
        }
`;

export const Button = styled.a`
    border: 2px solid rgb(74, 34, 26);
    border-radius: 100px;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 50px;
    transition: .5s;

    &:hover{
        background: rgb(74, 34, 26);
        color: #FFF;
    }

    &#best_seller{
        margin-bottom: 30px;
    }
`;

export const Background = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${background});
`;

export const Header = styled.div`
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);

    background: #F6F2E9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

    @media (max-width: 1050px){
        flex-direction: column;
        gap: 0px;
        padding: 10px 0px 20px 0px;

        a{
            text-decoration: underline;
        }
    }
    @media (max-width: 720px){
        display: none;
    }
`;

export const Menu = styled.ul`
    display: flex;
    gap: 40px;
    font-weight: bold;
`;

export const Logo = styled.div`
    img{
        width: 160px;

        &.logo_header{
            padding: 10px 5px;
        }
    }
`;

export const ContactInfo = styled.div`
    display: flex;
    gap: 40px;
`;

export const ContactItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &.banner{
        padding-top: 200px;
        height: 80vh;
        gap: 100px;
        flex-wrap: wrap-reverse;

        img{
            max-width: 500px;
        }

        @media (max-width: 600px){
            .text{
                width: 50%;
            }

            h1{
                font-size: 20px;
            }

            img{
                max-width: 300px;
            }
        }
    }

    &.reservation_adress{
        gap: 200px;
        background: url(${background3});
        background-position: bottom;
        padding: 120px 0px;

        h1, p{
            color: #fff;
        }

        form{
            width: 360px;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            color: #FFF;
        }
        form input[type="text"]{
            color: #FFF !important;
            border-radius: 4px;
            background: none;
            border: none;
            border-bottom: 2px solid rgb(118, 76, 36);
            outline: none;
            width: 100%;
        }
        form input[type="submit"]{
            border: 2px solid rgb(118, 76, 36);
            border-radius: 100px;
            font-size: 20px;
            font-weight: 700;
            padding: 10px 50px;
            width: 100%;
            transition: .5s;

            &:hover{
                background: rgb(118, 76, 36);
                color: #FFF;
            }
        }

        @media (max-width: 900px){
            flex-wrap: wrap;
        }
    }

    &.about_us{
        background: url(${background4});
        padding: 50px 0px;
        gap: 100px;
        
        h1, h2, h3, p{
            color: #FFF !important;
        }

        img{
            width: 400px;
        }

        
        @media (max-width: 720px){
            flex-direction: column;

            .text{
                width: 70%;
            }
        }
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.text{
        width: 500px;
        gap: 20px;
    }

    &.best_seller{
        width: 100%;
        text-align: center;
        align-items: center;
        background: url(${background2});

        .text{
            align-items: center;
            padding-top: 20px;
            
            p{
                color: #764c24;
                font-size: 15px;
                font-weight: 700;
            }
        }

        @media (max-width: 720px){
            p{
                &.Paragraph{
                    max-width: 400px;
                }
            }
        }
    }

    &.adress_box{
        align-items: center;
        gap: 20px;
    }

    
    &.reservation_box{
        align-items: center;
        gap: 20px;   
    }


    &.center{
        align-items: center;
        gap: 60px;
    }
`;

export const Separator = styled.div`
    width: 200px;
    height: 20px;
    background: url(${separator}) center no-repeat;
`;

export const WhiteSpace = styled.div`
    width: 100%;
    height: 15px;
`;

export const MobileSidebar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    position: fixed;
    background: #5c3601;
    z-index: 2;
    width: 40vw;
    height: 100vh;

    li{
        list-style: none;
    }

    li a{
        color: #F2F2F2;
        border: 1px solid #F2F2F2;
        padding: 6px 10px;
        border-radius: 100px;
        font-size: 20px;
    }
`;