import IService from '../Interface/IService';
import ILogin from '../../Model/Interface/ILogin';
import ILoginSecurity from '../../Model/Interface/ILoginSecurity';
import { User } from '../../Model/User';
import { Person } from '../../Model/Person';


const axios = require('axios');


export class SecurityService implements IService {

    public static async getUsers(){
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}/v1/users`),0)
        return response.data as Array<User>
    }

    public static async login(login : ILogin ) {
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/login/`,{...login}), 0)
        console.info("SecurityService",response)
        return response.data as ILoginSecurity    
    }

    public static async checkToken(token : string ) {
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}/check/${token}/token`), 0)
        return response.data as ILoginSecurity    
    }

    public static async createUser(data: User){
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/create`,{...data}),0)
        return Boolean(response.data.Success)
    }

    public static async createPerson(data: Person, userId: string){
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/create-profile/${userId}`,{...data}),0)
        return Boolean(response.data.Success)
    }

    public static async resetPassword(email: string){
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/restore-password/${email}`),0)
        return Boolean(response.data.Success)
    }

    private static async retry(asyncMethod: Promise<any>, count: number) {
 
        if (count >= 5) return null;

        try {
            var response = await asyncMethod;

            if (response.status != 200 && count <= 5) {
                throw response.err;
            }
            return response;
        }
        catch (err) {
            if (count <= 5) {
                console.info("retry ->", response);
                console.info("retry err ->", count);
                setTimeout(async () => await this.retry(asyncMethod, count + 1), 3000);
            }
            return err;
        }
    }
}

export default SecurityService;