import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;

    margin-top: 30px; 

    @media (max-width: 720px){
            flex-wrap: wrap;
    }
`;

export const Image = styled.img`
    width: 300px;
`;