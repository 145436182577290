import styled from "styled-components";

export const HamburgerContainer = styled.div`
  z-index: 10;
  display: none;
  width: 40px;
  height: 25px;
  position: absolute;
  cursor: pointer;

  @media (max-width: 720px){
    display: flex;
  }
`;

export const HamburgerBar = styled.div`
  z-index: 10;
  display: none;
  width: 100%;
  height: 3px;
  background-color: #0009;
  position: absolute;
  top: 30px;
  left: 20px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  
  @media (max-width: 720px){
    display: flex;
  }

  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #0009;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
  }

  &:before {
    top: -8px;
  }

  &:after {
    top: 8px;
  }
`;