import React from 'react';
import { Container } from './styles';

interface Shop {
    title: string;
    address: string;
    phone: string;
    schedule: string;
}

interface Props {
    shops: Shop[];
}

const ShopList: React.FC<Props> = ({ shops }) => {
    return (
        <Container>
            {shops.map((shop, index) => (
                <div key={index}>
                    <h2>{shop.title}</h2>
                    <p>{shop.address}</p>
                    <p>{shop.phone}</p>
                    <p>{shop.schedule}</p>
                </div>
            ))}
        </Container>
    );
};

export default ShopList;
