import React from 'react';
import { Link, Outlet } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import StoreIcon from '@mui/icons-material/Store';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import logoHz from "../../assets/images/logo/logo_horizontal.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useAuth } from '../../context/Auth';
import { cardDiv, icon, logo, spanStyledBySize } from '../../globalStyle';
import { ListItemIcon } from '@mui/material';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

function Admin() {
  const { signed, logout } = useAuth();
  function handleLogout() {
    logout().finally(() => { window.location.reload() });
    console.log('handleLogout ok')
  }

  function mobileIcons() {
    return (
      <div className='w-11/12 flex flex-wrap justify-center sm:flex-nowrap flex-row lg:flex-col'>
        {window.screen.width > 500 &&
          <div className="flex mx-3 my-6">
            <Link to="/" className="flex items-center" style={{ textDecoration: "none" }} >
              <img src={logoHz} style={logo(window.screen.width)} />
            </Link>
          </div>}
        <div className="flex items-center  mx-3 my-3">
          <Link to="/" className="flex items-center" style={{ textDecoration: "none" }}>
            <DashboardIcon style={icon(window.screen.width)} />
            <span style={spanStyledBySize(window.screen.width)}>Dashboard</span>
          </Link>

        </div>
        <div className="flex items-center mx-3 my-3">
          <Link to="usuarios" className="flex items-center" style={{ textDecoration: "none" }}>
            <PersonOutlineOutlinedIcon className="sm:text-sm" style={icon(window.screen.width)} />
            <span style={spanStyledBySize(window.screen.width)}>Usuários</span>
          </Link>
        </div>
        <div className="flex items-center mx-3 my-3">
          <Link to="produtos" className="flex items-center" style={{ textDecoration: "none" }}>
            <StoreIcon style={icon(window.screen.width)} />
            <span style={spanStyledBySize(window.screen.width)}>Produtos</span>
          </Link>
        </div>
        <div className="flex items-center mx-3 my-3">
          <Link to="categoria/add" className="flex items-center" style={{ textDecoration: "none" }}>
            <ControlPointDuplicateIcon style={icon(window.screen.width)} />
            <span style={spanStyledBySize(window.screen.width)}>Categorias</span>
          </Link>
        </div>        
        <div className="flex items-center mx-3 my-3">
          <InputOutlinedIcon style={icon(window.screen.width)} />
          <span onClick={handleLogout} style={spanStyledBySize(window.screen.width)}>Logout</span>
        </div>
        <div className="flex items-center mx-3 my-3">
          <FacebookIcon style={icon(window.screen.width)} />
          <TwitterIcon style={icon(window.screen.width)} />
          <InstagramIcon style={icon(window.screen.width)} />
        </div>
      </div>
    )
  }

  return (

    <div className="flex 
              flex-wrap 
              justify-center 
              lg:flex-row 
              lg:flex-nowrap 
              container 
              lg:my-9
              w-full
              mx-auto">
      {signed &&
        <div className="flex
                    lg:basis-1/5
                    lg:w-52
                    lg:items-start
                    w-max
                    mx-6
                    lg:mx-auto
                    my-2
                    " style={cardDiv}>

          {mobileIcons()}
        </div>}
      <div className="flex
      w-80
      sm:w-11/12
      justify-center
      flex-auto
      mx-auto
      my-2">
        <Outlet />
      </div>
    </div>
  )
}
export default Admin


