import React from 'react';
import { Container, Image } from './styles'

interface Product {
    title: string;
    image: string;
    category: string;
    price: string;
}

interface Props {
    products: Product[];
}

const ProductList: React.FC<Props> = ({ products }) => {
    return (
        <Container>
            {products.map((product, index) => (
                <div key={index}>
                    <h2>{product.title}</h2>
                    <Image src={product.image} alt={product.title} />
                    <p>{product.category}</p>
                    <p>{product.price}</p>
                </div>
            ))}
        </Container>
    );
};

export default ProductList;