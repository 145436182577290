import React, { MouseEventHandler, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import StockService from "../../Service/Implementation/StockService";
import { button, buttonActive, buttonHover, cardDiv, icon, spanStyled, spanStyledBySize, titleStyled } from "../../globalStyle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IProductCategory from "../../Model/Interface/IProductCategory";
import IProduct from "../../Model/Interface/IProduct";
import ProductModel from "../../Model/ProductModel";
import IImageUpload from "../../Model/Interface/IImageUpload";
import { StorageService } from "../../Service/Implementation/StorageService";
import { CategoryService, ProductService } from "../../Service";
import logo from "../../assets/images/EmUso/logo.png";
import IImage from "../../Model/Interface/IImage";
import { useAuth } from "../../context/Auth";
import Loader from "./Loader";
import IProductOptions from "../../Model/Interface/IProductOptions";
import AddIcon from '@mui/icons-material/Add';
import Accordion from "@mui/material/Accordion/Accordion";
import { AccordionSummary, Typography } from "@mui/material";

export function ProductForm(props: {
  item: IProduct,
  isUpdate: boolean,
}) {
  const [buttonStyle, setButtonStyle] = useState<{}>(button);

  const handleMouseEnter = (event: any) => {
    let buttonChange =
      setButtonStyle(buttonHover)
  };

  const handleMouseLeave = () => {
    setButtonStyle(button)
  };

  const handleMouseClick = () => {
    setButtonStyle(buttonActive)
  };

  const labels = ["unidade", "mililitro", "litro", "grama", "miligrama", "kilo"]
  const [product, setProduct] = useState<IProduct>(props.item)
  const [price, setPrice] = useState('0')
  const [name, setName] = useState(props.item.name)
  const [description, setDescription] = useState(props.item.big_Description)
  const [shortDescription, setShortDescription] = useState(props.item.description)
  const [fileUrl, setFileUrl] = useState(props.item.url_image)
  const [categories, setCategories] = useState(new Array<IProductCategory>())
  const [categorieSelected, setCategorieSelected] = useState<IProductCategory>(props.item.product_Category)
  const [image, setImage] = useState<IImageUpload>({
    name: "",
    id: uuid(),
    objectId: uuid(),
    created: 0,
    ownerId: uuid(),
    updated: 0,
    entityId: uuid(),
    url: props.item.url_image,
    file: null
  } as IImageUpload)
  const { signed, checkLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(true)
  const [unitValue, setUnitValue] = useState(0.0)
  const [unitLabel, setUnitLabel] = useState(labels[0])
  const isValid = (itemValid: IProduct) => { return itemValid.name && itemValid.options.length > 0 && itemValid.product_Category }
  const [options, setOptions] = useState(props.item.options)
  const [expanded, setExpanded] = useState('')

  useEffect(() => {
    setIsLoading(true)

    if (!signed && !checkLogin()) {
      console.log('should redirect')
      window.open("/", '_self')
    }

    if (categories && categories.length < 1) {
      try {
        CategoryService.getProductCategories().then((result) => setCategories(result))
        setFileUrl(logo)
      } catch (error) {
        alert(error)
      }
    }


    if (props.item.product_Category && props.item.product_Category.name != '') {
      setCategorieSelected(props.item.product_Category)
    }

    if (product && props.item.id != product.id) {
      setProduct(product)
      setPrice('0.00')
      setUnitValue(0)
      setUnitLabel(labels[0])
      setDescription(product.big_Description)
    }
    if (product.url_image) setFileUrl(product.url_image)
    else setFileUrl(logo)

    setIsLoading(false)

  }, [product, signed, categories, categorieSelected, fileUrl, options])

  function handlePrice(newPrice: string) {
    console.info("handle price", newPrice)
    if (!newPrice || newPrice == '' || newPrice == '0' || newPrice.includes('-')) {
      setPrice('0.00')
    }
    else {
      newPrice = newPrice.replaceAll('.', '')

      if (newPrice.length == 1 || newPrice.length == 2) {
        for (let c = newPrice.length; c < 3; c++) {
          newPrice = `0${newPrice}`
        }
      }

      if (newPrice.length >= 3 && newPrice.length <= 8) {
        newPrice = newPrice.substring(0, newPrice.length - 2) + "." + newPrice[newPrice.length - 2] + newPrice[newPrice.length - 1];
      }

      if (newPrice.length >= 9) newPrice = '0'

      if (Number.parseFloat(newPrice)) {
        let parsedFloat = Number.parseFloat(newPrice)
        setPrice(parsedFloat.toFixed(2))
      }
    }
  }

  async function handleOnSubmit(event: any) {

    let newProduct = { ...product }
    if (image.file) newProduct = await addImage(image, image.file);

    if (!newProduct.url_image) {
      newProduct.url_image = newProduct.images.url
    }

    if (newProduct.url_image && newProduct.url_image != "") {

      newProduct.tax_code = 0;
      newProduct.big_Description = description
      newProduct.description = shortDescription
      newProduct.active = true
      newProduct.name = name

      if (categorieSelected || !newProduct.product_Category) {
        newProduct.product_Category = categorieSelected
      }

      console.log(product)
      setProduct(newProduct)

      if (!isValid) alert('Preencha todos os campos')
      else {
        try {
          if (!props.isUpdate) {
            let productResponse = await ProductService.addProduct(newProduct);
            alert('Inserido com sucesso')
            if (productResponse) {
              await ProductService.addOption(productResponse.objectId, options)
            }
            window.location.reload()
          }
          else {
            let result = await ProductService.updateProduct(newProduct)
            console.info("update product", result)
            alert('Atualizado com sucesso')

          }
          setProduct(ProductModel.newProduct())
        }
        catch (e) {
          alert('Falha - Produto não adicionado')
        }
      }

    }
    else {
      alert('Insira uma imagem')
    }
  }

  async function deleteImage(event: any) {
    try {
      setIsLoading(true)
      event.preventDefault()
      let newProd = { ...product }
      let imageNull = {
        name: "",
        id: uuid(),
        objectId: uuid(),
        created: 0,
        ownerId: uuid(),
        updated: 0,
        entityId: uuid(),
        url: logo,
        file: null
      } as IImageUpload
      newProd.images = imageNull
      newProd.url_image = "https://defornoefogao.com.br/static/media/logo_horizontal.0ac4a0e6.png";
      setFileUrl(logo)
      setImage(imageNull)
      await setProduct(newProd)
      let resultProd = await ProductService.updateProduct(newProd)
      let fileName = product.url_image.split('/')[product.url_image.split('/').length - 1]
      let result = await StorageService.deleteImage(fileName)

      console.info('update after delete image', newProd)
      await setImage(newProd.images as IImageUpload)
      await setProduct(newProd)
      console.info('update after delete image', resultProd)
      return newProd;
    } catch {
      return image
    }
  }

  async function addImage(image: IImage, file: File) {
    let newProd = { ...product }
    let addedImage = { ...image } as IImageUpload
    try {
      let imageAdded = await StorageService.addImage(file, product.objectId) as IImageUpload

      if (imageAdded) {

        if (!imageAdded.url) imageAdded.url = URL.createObjectURL(file)

        addedImage.file = file
        addedImage.url = imageAdded.url
        newProd.images = imageAdded
        newProd.url_image = imageAdded.url

        setImage(addedImage)
        setFileUrl(imageAdded.url)
        await ProductService.updateProduct(newProd)
        setProduct(newProd)
      }

    } catch {
      alert("Imagem não adicionada");
    }
    return newProd
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    let newProd = { ...product }

    if (e && e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1000 > 1000) {
        alert('imagem muito grande')
      }
      else {
        let newImage = {
          name: e.target.files[0].name,
          id: `${newProd.id}`,
          url: "",
          file: e.target.files[0]
        } as IImageUpload;
        newProd.images = newImage
        newProd.url_image = URL.createObjectURL(e.target.files[0])
        setFileUrl(newProd.url_image)
        setImage(newImage)
      }
    }
    else {
      setFileUrl(logo)
    }
    setProduct(newProd)
  }

  function handleCategory(event: any) {
    if (event.target.value == '') {
      setCategorieSelected(categories[0])
    }
    else {
      let newCategorySelected = categories.find((result) => result.id == event.target.value);
      if (newCategorySelected) {
        let prod = { ...product }
        prod.product_Category = newCategorySelected;
        setProduct(prod)
        setCategorieSelected(newCategorySelected);
      }
    }
  }

  async function deleteRelation(e: any, optionsToDel: IProductOptions) {
    e.preventDefault()

    if (options.length == 1) {
      alert("Não é possível deletar, o produto precisa de pelo menos 1 opção adicionada")
    }
    else {
      try {
        let newprod = { ...product }
        let optionsFiltered = { ...options }
        console.log(optionsToDel)

        if (optionsToDel) {
          let productOptionsListFiltered = options.filter(opt => opt.objectId != optionsToDel.objectId) as IProductOptions[]
          if (productOptionsListFiltered) {
            console.log(productOptionsListFiltered)

            newprod.options = productOptionsListFiltered

            let relationResult = await ProductService.deleteRelation(newprod.objectId, optionsToDel.objectId, "options")
            console.log(optionsToDel)
            setOptions(productOptionsListFiltered)
            setProduct(newprod)
            alert('sucesso')
          }
        }
      } catch (e: any) {
        console.error("delete relation", e)
        alert('falha')
      }
    }
  }

  async function addRelation(e: any) {
    if (price && unitValue > 0 && unitLabel && unitLabel != "") {
      try {

        e.preventDefault()
        let newprod = { ...product }
        let optionsValues = { ...options } as IProductOptions[]
        let opt = [{
          active: true,
          defaultPrice: Number.parseFloat(price),
          id: uuid(),
          objectId: uuid(),
          quantity: Number.parseFloat(unitValue.toString(2)),
          taxCode: 0,
          unitLabel: unitLabel,
          unitValue: Number.parseFloat(unitValue.toString(2)),
          created: Date.now(),
          updated: Date.now(),
        }] as IProductOptions[]
        console.info("New Options", opt)


        if (props.isUpdate) {
          let objectId = await ProductService.addOption(newprod.objectId, opt)
          let optionsObjectId = options.map(ov => { return ov.objectId })
          optionsObjectId.push(objectId)
          console.info("objectId", objectId)
          console.info("optionsObjectId array", optionsObjectId)
          if (objectId) await ProductService.addRelationBulk(newprod.objectId, optionsObjectId, 'options')
        }

        optionsValues = options.concat(opt)
        newprod.options = optionsValues
        console.info("concat options", optionsValues)

        setPrice('0.00')
        setUnitLabel(labels[0])
        setUnitValue(0)

        setProduct(newprod)
        setOptions(optionsValues)
        console.info("IProductOptions", optionsValues)

      }
      catch (e: any) {
        alert(e.message)
      }
    }
    else {
      alert("Opções precisam de Preço, Medida e Unidade de Medida")
    }
  }

  async function handleUnitLabel(e: any) {
    e.preventDefault()
    if (e.target.value) setUnitLabel(e.target.value)
  }

  const renderOpts = (opt: IProductOptions | null, index: number) => {
    return (
      <div className="container col-span-full my-10 py-10" style={cardDiv} >
        <div className="grid grid-cols-3 gap-1 w-full mr-10 mb-10">
          <div className="col-span-3 mx-auto my-8">
            <span style={titleStyled}>
              {index <= 0 && "Nova Opção"}
              {index > 0 && `Opção ${index}`}
            </span>
          </div>
          <div className="col-span-2  w-full">
            <label className="w-full" htmlFor="weight" style={spanStyledBySize(window.screen.width)} >
              Peso - Volume - Quantidade
            </label>
            <br />
            <input
              type="number"
              id="unitValue"
              placeholder={'0'}
              aria-describedby="weight"
              onChange={(e) => { setUnitValue(e.target.valueAsNumber <= 0 ? 0 : e.target.valueAsNumber) }}
              onBlur={(e) => { setUnitValue(e.target.valueAsNumber <= 0 ? 0 : e.target.valueAsNumber) }}
              className="my-2 mx-2 w-11/12 border-2 rounded-lg text-center"
              defaultValue={opt ? opt.unitValue : unitValue}
              value={opt ? opt.unitValue : unitValue}
              disabled={opt != null} />

          </div>
          <div className="col-span-1 w-full">
            <label className="w-full" htmlFor="price" style={spanStyledBySize(window.screen.width)}>
              Preço
            </label>
            <br />
            <input
              type="text"
              id="price"
              placeholder={'0.00'}
              aria-describedby="price"
              onChange={(e) => { handlePrice(e.target.value) }}
              onBlur={(e) => { handlePrice(e.target.value) }}
              className="my-2 mx-2 w-11/12 border-2 rounded-lg text-center"
              defaultValue={opt ? opt.defaultPrice.toFixed(2).toString() : price}
              value={opt ? opt.defaultPrice.toFixed(2).toString() : price}
              disabled={opt != null}
            />
          </div>
          <div className="col-span-2 w-full">
            <label className="w-full" htmlFor="unityLabel" style={spanStyledBySize(window.screen.width)}>
              Medida
              <br />

              <select
                disabled={opt != null}
                name="unityLabel"
                id="unityLabel"
                style={spanStyledBySize(window.screen.width)}
                className="my-2 mx-2 w-11/12 border-2 rounded-lg text-center"
                onClick={handleUnitLabel}
              >
                {labels.length > 0 && opt
                  && <option
                    className="mx-2"
                    key={opt.unitLabel}
                    value={opt.unitLabel}
                    selected
                    disabled
                  >
                    {opt.unitLabel}
                  </option>}
                {labels.map(label =>
                  <option className="mx-2" key={`${label}`}
                    value={label}>
                    {label}
                  </option>)}
              </select>
            </label>
          </div>
          <div className="my-2 mx-2 col-start-3  w-10/12 flex justify-center mb-10">
            {!opt &&
              <button onClick={async (e) => { await addRelation(e) }} disabled={(name != null && name != '' && price != "0.00")}>
                <AddIcon style={icon(window.screen.width)} onClick={async (e) => { await addRelation(e) }} />
                Adicionar Opção
              </button>}
            {opt &&
              <button onClick={async (e) => { await deleteRelation(e, opt) }}>
                <DeleteForeverIcon style={icon(window.screen.width)} onClick={async (e) => { await deleteRelation(e, opt) }} />
                Deletar Opção
              </button>}
          </div>
        </div>
      </div>

    )
  }

  const renderDetails = () => {
    return (
      <div className="grid grid-cols-4 gap-1 w-7/12 mx-auto" >
        <div className="col-span-4 mx-auto my-8">
          <span style={titleStyled}>
            {!props.isUpdate || "Adicionar "}
            {props.isUpdate && "Atualizar "}
            Produto</span>
        </div>
        <div className="col-span-4 lg:col-span-2 w-full">
          <label htmlFor="productName" style={spanStyledBySize(window.screen.width)}>Nome do Produto/Prato
            <input
              type="text"
              id="productName"
              placeholder={"Nome do produto"}
              aria-describedby="Product Name"
              onChange={(e) => { setName(e.target.value) }}
              onBlur={(e) => { setName(e.target.value) }}
              className="my-3 w-full border-2 rounded-lg text-center"
              value={name}
              defaultValue={product.name}
            />
          </label>
        </div>
        <div className="col-span-4 lg:col-span-2 w-full">
          <label htmlFor="productCategory" style={spanStyledBySize(window.screen.width)}>Categoria
            <select
              name="categories"
              id="categories"
              style={spanStyledBySize(window.screen.width)}
              className="my-3 w-full border-2 rounded-lg text-center"
              onChange={handleCategory}
              onClick={handleCategory}
            >
              {props.item.product_Category.name
                && <option className="mx-2" key={props.item.product_Category.id}
                  value={props.item.product_Category.id}
                  selected>
                  {props.item.product_Category.name}
                </option>}
              {categories.map(cat => {
                if (props.item.product_Category.id != cat.id) {
                  return (<option className="mx-2" key={`${cat.id}`}
                    value={cat.id}>
                    {cat.name}
                  </option>)
                }
                else {
                  return (
                    <option key={'default'}
                      value={uuid()}>
                      {props.item.product_Category.name}
                    </option>
                  )
                }
              })}
            </select>
          </label>
        </div>
        <div className="col-span-4 w-full">
          <label htmlFor="productDescriptionShort" style={spanStyledBySize(window.screen.width)}>
            Mini Descrição do Produto/Prato
            <textarea
              id="productDescriptionShort"
              placeholder={"Descreva sobre o produto"}
              aria-describedby="Product Description"
              rows={1}
              onChange={(e) => { setShortDescription(e.target.value) }}
              onBlur={(e) => { setShortDescription(e.target.value) }}
              className="my-3 w-full border-2 rounded-xl text-center"
              value={description}
              defaultValue={product.big_Description}
            />
          </label>
        </div>
        <div className="col-span-4 w-full">
          <label htmlFor="productDescription" style={spanStyledBySize(window.screen.width)}>
            Descrição do Produto/Prato
            <textarea
              id="productDescription"
              placeholder={"Descreva sobre o produto"}
              aria-describedby="Product Description"
              rows={5}
              onChange={(e) => { setDescription(e.target.value) }}
              onBlur={(e) => { setDescription(e.target.value) }}
              className="my-3 w-full border-2 rounded-xl text-center"
              value={description}
              defaultValue={product.big_Description}
            />
          </label>
        </div>
      </div>
    )
  }

  return (
    <div className="container">

      {categories &&
        <Accordion expanded={expanded == 'detail'} className="mx-3 my-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => {
              if(expanded == 'detail') setExpanded('')
              else setExpanded('detail')
            }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="my-2"
          >
            <Typography style={titleStyled}>Detalhes</Typography>
          </AccordionSummary>
          {renderDetails()}
        </Accordion>}

      {<Accordion expanded={expanded == 'options'} className="mx-3 my-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => {
              if(expanded == 'options') setExpanded('')
              else setExpanded('options')
            }
            
            
            } />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="my-2"
          >
            <Typography style={titleStyled}>Opções e Preço</Typography>
          </AccordionSummary>
          <div className="col-span-4 w-full">
            {renderOpts(null, -1)}
          </div>
          {product
          && options.length > 0 
          && options.map((opt, index) => {
            if (opt.defaultPrice > 0) {
              return (
                renderOpts(opt, index + 1)
              )
            }
          })
          }
        </Accordion>}
        {
            fileUrl &&
            fileUrl.length > 0 &&
            <Accordion expanded={expanded == 'image'} className="mx-3 my-2">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => 
                  {
                    if(expanded == 'image') setExpanded('')
                    else setExpanded('image')
                  }
                  
                } />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className="my-2"
              >
                <Typography style={titleStyled}>Imagens</Typography>
              </AccordionSummary>
              <div className="grid grid-cols-1 gap-1 w-7/12 mx-auto my-2 " >

              <div className="mx-auto col-span-full my-3">
                <button onClick={deleteImage}>
                  <DeleteForeverIcon style={icon(window.screen.width)} />
                </button>
                <img src={product && props.isUpdate && product.url_image && product.url_image.length > 1 ? product.url_image : fileUrl} width="250px" height="250px" onError={() => alert("adicione uma imagem")} />
              </div>
              </div>

            </Accordion>

          }
      {

        <div className="grid grid-cols-1 gap-1 w-7/12 mx-auto my-2 " >
          <div className="col-span-full  col-start-1">
            <label htmlFor="file" style={spanStyledBySize(window.screen.width)}>
              Imagem
            </label>
            <input
              type="file"
              id="file"
              placeholder={"..."}
              aria-describedby="arquivo"
              onChange={(e) => handleFileChange(e)}
              className="mx-4 my-3 py-2 w-full sm:w-11/12 border-2 rounded-lg text-center"
            />
          </div>
          {!props.isUpdate &&
            <div className="col-span-4 mx-auto mb-9">
              <button
                key="Adicionar"
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseClick}
                onMouseUp={handleMouseEnter}
                onClick={(e) => handleOnSubmit(e)}
              >
                Adicionar
              </button>
            </div>}
          {props.isUpdate &&
            <div className="col-span-4 mx-auto mb-9">
              <button
                key="Atualizar"
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseClick}
                onMouseUp={handleMouseEnter}
                onClick={(e) => handleOnSubmit(e)}
              >
                Atualizar
              </button>
            </div>
          }
        </div>
      }
    </div>
  )
}