import React, { useEffect, useState } from 'react';
import ILoginSecurity from '../Model/Interface/ILoginSecurity';
import { titleStyled } from '../globalStyle';


export default function home() {

    const [userInfo, setUserInfo] = useState<ILoginSecurity | null>(null);

    useEffect(() => {
        if (!userInfo) {
            let storageToken = localStorage.getItem("authInfo")

            if (storageToken) {
                let parsedObject = JSON.parse(storageToken) as ILoginSecurity
                setUserInfo(parsedObject)
            }
        }
    }, [userInfo])

    return (
        <span style={titleStyled}>Bem Vindo, 
            {userInfo && userInfo.name && userInfo.name.length > 2
            ? ` ${userInfo.name[0].toUpperCase()}${userInfo.name.slice(1)}`
            : " Usuário"}</span>
    )
}