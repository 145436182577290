import React from 'react';
import { HamburgerContainer, HamburgerBar } from './styles';

interface Props {
    onClick: () => void;
}

const MenuMobile = (props: Props) => {
    const { onClick } = props;
    return (
        <HamburgerContainer onClick={onClick}>
            <HamburgerBar />
            <HamburgerBar />
            <HamburgerBar />
        </HamburgerContainer>
    );
}

export default MenuMobile;
