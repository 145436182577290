import React from 'react';

import Footer from '../../Component/footer/footer';

import breads from '../../assets/images/institucional/breads.png';
import LogoImg from '../../assets/images/institucional/logo.png';

// Products images
import baguete from '../../assets/images/institucional/baguete.png';
import sonho from '../../assets/images/institucional/sonho.png';
import croassaint from '../../assets/images/institucional/croassaint.png';
import paoseila from '../../assets/images/institucional/paoseila.png';
import aboutUsImage from '../../assets/images/institucional/about_us.png';

import { Background, ContactInfo, ContactItem, Header, Logo, Menu, Page, Separator, Row, Column, WhiteSpace, Button, MobileSidebar } from './styles';
import ProductList from '../../Component/productList/productList';
import ShopList from '../../Component/shopList/shopList';
import MenuMobile from '../../Component/MenuMobile/MenuMobile';

const products = [
    {
        title: 'Sonho',
        image: sonho,
        category: 'Padaria',
        price: 'Consulte',
    },
    {
        title: 'Baguete',
        image: baguete,
        category: 'Padaria',
        price: 'Consulte',
    },
    {
        title: 'croassaint',
        image: croassaint,
        category: 'Padaria',
        price: 'Consulte',
    },
    {
        title: 'Pão de Fermentação Natural',
        image: paoseila,
        category: 'Padaria',
        price: 'Consulte',
    }
];

const shops = [
    {
        title: 'Unidade 1',
        address: 'Avenida Morumbi, 4770',
        phone: '(11) 97502-4066',
        schedule: '08:00 - 19:00',
    },
    {
        title: 'Unidade 2',
        address: 'R. Visc. de Nacar, 115',
        phone: '(11) 97502-4066',
        schedule: '08:00 - 19:00',
        
    }
];


const Institucional: React.FC = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    return (
        <Page>

            <MenuMobile onClick={() => setMenuOpen(!menuOpen)} />

            {/* Header + banner */}
            <section>
                <Background>

                    {menuOpen &&
                        <MobileSidebar>
                            <li><a href="#">Produtos</a></li>
                            <li><a href="#">Lojas / horário</a></li>
                            <li><a href="#">Contatos</a></li>
                        </MobileSidebar>
                    }

                    <Header>
                        <Menu className='Menu'>
                            <li><a href="#">Produtos</a></li>
                            <li><a href="#">Lojas / horário</a></li>
                            <li><a href="#">Contatos</a></li>
                        </Menu>
                        <Logo>
                            <img className='logo_header' src={LogoImg} alt="logo" />
                        </Logo>
                        <ContactInfo className='ContactInfo'>
                            <ContactItem>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#4a221a" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                </svg>
                                <p>(11) 97502-4066</p>
                            </ContactItem>
                            <ContactItem>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#4a221a" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx={12} cy={12} r={9} />
                                    <polyline points="12 7 12 12 15 15" />
                                </svg>
                                <p>(seg/sex) 08:00 - 19:00</p>
                            </ContactItem>
                        </ContactInfo>
                    </Header>

                    <Row className='banner'>
                        <Column className='text'>
                            <h2>Rede de padarias sob demanda</h2>
                            <h1>DeForno&amp;Fogão</h1>
                            <p>
                                Temos mais de 120 produtos, faça seu pedido em uma de nossas
                                unidades mais próxima de sua casa.
                            </p>
                            <Button>Conhecer</Button>
                        </Column>
                        <div className="image">
                            <img src={breads} alt="banner" />
                        </div>
                    </Row>
                </Background>
            </section>

            <WhiteSpace />

            {/* Sobre */}
            <section>
                <Row className='about_us'>
                    <Column>
                        <img src={aboutUsImage} alt="sobre nós" />
                    </Column>
                    <Column className='text'>
                        <h2>Aderindo tecnologia ao dia dia</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt amet, voluptatum fugit iste dolores animi laudantium sunt, neque quaerat praesentium, sit at. Amet dolorem optio, repellendus dolore tempore expedita ipsam.</p>
                    </Column>
                </Row>
            </section>

            <WhiteSpace />

            {/* produtos mais vendidos*/}
            <section>
                <Column className='best_seller'>
                    <Column className='text'>
                        <h1 className='title_font'>Mais vendidos</h1>
                        <Separator />
                        <p className='Paragraph'>Oferecemos algo diferente para nossos clientes e garantimos que você sempre desfrute de uma experiência gastronômica memorável pela manhã.</p>
                    </Column>
                    <Row>
                        <Column className='center'>
                            <ProductList products={products} />
                            <Button id='best_seller'>Ver Mais</Button>
                        </Column>
                    </Row>
                </Column>
            </section>

            <WhiteSpace />

            {/* Contatos e endereços */}
            <section>
                <Row className='reservation_adress'>
                    <Column className='reservation_box'>
                        <h1 className='title_font'>Fazer reserva</h1>
                        <Separator />
                        <p>Entre em contato conosco para mais informações.</p>
                        <form>
                            <input type="text" placeholder="Nome" />
                            <input type="text" placeholder="Telefone" />

                            <input type="submit" value="Abrir reserva" />
                        </form>
                    </Column>
                    <Column className='adress_box'>
                        <h1 className='title_font'>Endereços</h1>
                        <Separator />
                        <p>Encontre uma de nossas unidades mais próxima de você.</p>

                        <ShopList shops={shops} />
                    </Column>
                </Row>
            </section>

            <WhiteSpace />

            {/* Footer */}
            <Footer />
        </Page >
    );
}

export default Institucional;