import { CCol, CContainer, CSpinner } from "@coreui/react";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export default class Loader extends React.Component {

    render() {
        return (
            <div className="container">
                <div className="mx-auto">
                    <CircularProgress />
                </div>
            </div>
        )
    }
}