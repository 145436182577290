import IProduct from '../../Model/Interface/IProduct'
import IProductCategory from '../../Model/Interface/IProductCategory';
import IProductOptions from '../../Model/Interface/IProductOptions';
import IService from '../Interface/IService';


const axios = require('axios');


export class ProductService implements IService {

    public static async getProducts() {
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}/product/all/`), 0)
        return response.data.result as IProduct[]    
    }

    public static async getProduct(productId: string){
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}${"/product?productId="}${productId}`), 0)
            return response.data.result as IProduct
    }

    public static async addProduct(product: IProduct) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/create/`, { ...product })
        return response.Data as IProduct
    }

    public static async updateProduct(product: IProduct) {
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/edit/${product.id}/product`, { ...product }), 0)
        return response.Data as IProduct
    }

    public static async addRelation(productObjId:string, entityObjId:string, entityType:string) {
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/relation/add?productObjId=${productObjId}&entityObjId=${entityObjId}&entityType=${entityType}`), 0)
        console.log(response.data)
        return response.Data as boolean
    }
    public static async addRelationBulk(productObjId:string, entityObjId:string[], entityType:string) {
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/relation/addBulk?productObjId=${productObjId}&entityType=${entityType}`,entityObjId), 0)
        console.log(response.data)
        return response.data as boolean
    }
    public static async deleteRelation(productObjId:string, entityObjId:string, entityType:string) {
        
        const response = await this.retry(await axios.delete(`${process.env.REACT_APP_API_URL}/v1/product/relation/delete?productObjId=${productObjId}&entityObjId=${entityObjId}&entityType=${entityType}`), 0)
        console.log(response.data)
        return response.data as boolean
    }
    
    public static async addOption(productObjId:string, options: IProductOptions[]) {
        console.info("addOption", options)
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/options/add?productObjId=${productObjId}`, options), 0)
        console.info("addOption",response.data)
        return response.data as string
    }

    private static async retry(asyncMethod: Promise<any>, count: number) {
        if (count >= 5) return null;

        try {
            var response = await asyncMethod;

            if (response.status != 200 && count <= 5) {
                throw response.err;
            }
            return response;
        }
        catch (err) {
            if (count <= 5) {
                console.info("retry ->", response);
                console.info("retry err ->", count);
                setTimeout(async () => await this.retry(asyncMethod, count + 1), 3000);
            }
            return err;
        }

    }
}

export default ProductService;