import React from "react";

import { Row, Column, Container, Title, Adress } from "./styles";

import PlayStoreLogo from '../../assets/images/institucional/playstore_logo.svg';
import AppStoreLogo from '../../assets/images/institucional/apple_logo.svg';
import getPlayStore from '../../assets/images/institucional/google-play-badge.png';
import getAppStore from '../../assets/images/institucional/app-store-badge.png';

import LogoImg from '../../assets/images/institucional/logo.png';

export default class footer extends React.Component {

    render() {
        return (
            <Container>
                <Column>
                    <Adress className="adress">
                        <img src={LogoImg} alt="Logo" />

                        <div>
                            <p>Avenida Morumbi, 4770</p>
                            <p>Telefone: +55 (11) 97502-4066</p>
                            <p className="copy">&copy; Forno&Fogão desenvolvido por MrSolutions</p>
                        </div>
                    </Adress>
                </Column>

                <Column>
                    <Title>Links</Title>
                    <a href="#">Produtos</a>
                    <a href="#">Lojas / horário</a>
                    <a href="#">Contatos</a>
                </Column>

                <Column>
                    <Row className="link_download">
                        <Column>
                            <Title>Android</Title>
                            <img src={PlayStoreLogo} alt="QRCODE - PLAYSTORE" />
                            <img className="link" src={getPlayStore} alt="LINK - PLAYSTORE" />
                        </Column>
                        <Column>
                            <Title>IOS</Title>
                            <img src={AppStoreLogo} alt="QRCODE - APPSTORE" />
                            <img className="link" src={getAppStore} alt="LINK - APPSTORE" />
                        </Column>
                    </Row>
                </Column>


            </Container>
        )
    }
}