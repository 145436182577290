

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from '../Component/App/Sidebar';
import Home from '../pages/home';
import { ProductForm } from '../Component/App/ProductForm';
import ProductTable from '../pages/ProductTable';
import UserTable from '../pages/UserTable';
import ProductModel from '../Model/ProductModel';
import CategoryForm from '../Component/App/CategoryForm';
import { v4 as uuid } from 'uuid';
import IImageUpload from '../Model/Interface/IImageUpload';

export function SignedRoutes() {

 return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Admin />}>
          <Route index element={<Home />} />
          <Route path="produtos" element={<ProductTable  />} />
          <Route path="usuarios" element={<UserTable  />} />
          <Route path="produtos/add" element={
            <ProductForm {
              ...{ 
                  item: ProductModel.newProduct(), 
                  isUpdate: true,
            }}  
            />
            } />
          <Route path="categoria/add" element={
          <CategoryForm 
            item={{
              name: "",
              id: uuid(),
              image: {
                  name: "",
                  id: uuid(),
                  objectId: uuid(),
                  created: 0,
                  ownerId: uuid(),
                  updated: 0,
                  file: null,
                  url: "",
              } as IImageUpload,
              objectId: uuid(),
              created: 0,
              ownerId: uuid(),
              updated: 0,
              url_image:""
            }}
            categorieList={[]} 
            isUpdate={false}/>
          }/>
        </Route>
      </Routes>
    </BrowserRouter>
 );
};

export default SignedRoutes;