import IProduct from './Interface/IProduct'
import { v4 as uuid } from 'uuid'
import IImage from './Interface/IImage';
import IProductCategory from './Interface/IProductCategory';
import IImageUpload from './Interface/IImageUpload';
import IProductOptions from './Interface/IProductOptions';



export default class ProductModel implements IProduct {
    id: string;
    objectId: string;
    name: string;
    description: string;
    createdDate: Date;
    updatedDate: Date;
    active: boolean;
    userId: string;
    default_Price: number;
    tax_code: number;
    big_Description: string;
    url: string;
    product_Category: IProductCategory;
    images: IImage;
    url_image: string;
    options: IProductOptions[];

    /**
     *
     */
    constructor(id: string,
        objectId: string,
        name: string,
        description: string,
        createdDate: Date,
        active: boolean,
        updatedDate: Date,
        userId: string,
        default_Price: number,
        tax_code: number,
        big_Description: string,
        url: string,
        product_Category: IProductCategory,
        images: IImage,
        url_image: string,
        options: IProductOptions[]

    ) {

        this.id = id;
        this.objectId = objectId;
        this.name = name;
        this.description = description;
        this.createdDate = createdDate;
        this.active = active;
        this.updatedDate = updatedDate;
        this.userId = userId;
        this.default_Price = default_Price;
        this.tax_code = tax_code;
        this.big_Description = big_Description;
        this.url = url;
        this.product_Category = product_Category;
        this.images = images;
        this.url_image = url_image;
        this.options = options
    }

    static newProduct() {
        let id = uuid();
        let userId = uuid();
        return new ProductModel(
            id,
            uuid(),
            "",
            "",
            new Date(),
            false,
            new Date(),
            userId,
            0,
            0,
            '',
            '',
            {
                name: "",
                id: uuid(),
                image: {
                    name: "",
                    id: uuid(),
                    objectId: uuid(),
                    created: 0,
                    ownerId: uuid(),
                    updated: 0,
                    entityId:  uuid(),
                    url: ""
                },
                objectId: uuid(),
                created: 0,
                ownerId: uuid(),
                updated: 0,
                url_image:""
            },
            {
                name: "",
                id: uuid(),
                objectId: uuid(),
                created: 0,
                ownerId: uuid(),
                updated: 0,
                entityId:  uuid(),
                url: ""
            },
            "",
           [ {
            active:true,
            defaultPrice: 0,
            id:uuid(),
            objectId: uuid(),
            quantity: 0,
            taxCode: 0,
            unitLabel: "unit",
            unitValue: 1,
            created: 0,
            updated:0,
          }]) as IProduct;
    }
}