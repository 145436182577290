import IProduct from '../../Model/Interface/IProduct';
import IService from '../Interface/IService';

const axios = require('axios');

export class StockService implements IService {

    public static async getStockProducts() {
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}/v1/product/all/`), 0)
        return response.data as IProduct[]
    }

    public static async getStockProduct(productId: string){
        const response = await this.retry(await axios.get(`${process.env.REACT_APP_API_URL}${"/stock?productId="}${productId}`), 0)
            return response.data.result as IProduct
    }

    public static async addStockProduct(stockInformation: IProduct) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/product/create/`, { ...stockInformation })
        return response.status == 200
    }

    public static async updateStockProduct(product: IProduct) {
        const response = await this.retry(await axios.post(`${process.env.REACT_APP_API_URL}/stock/update/${'3fa85f64-5717-4562-b3fc-2c963f66afa6'}`, { ...product }), 0)
        return response.status == 200
    }    
    
    public static async deleteProduct(id: string) {
        const response = await this.retry(await axios.delete(`${process.env.REACT_APP_API_URL}/v1/product/delete/${id}`), 0)
        return response.status == 200
    }    

    private static async retry(asyncMethod: Promise<any>, count: number) {
        if (count >= 5) return null;

        try {
            var response = await asyncMethod;

            if (response.status != 200 && count <= 5) {
                throw response.err;
            }
            return response;
        }
        catch (err) {
            if (count <= 5) {
                console.info("retry ->", response);
                console.info("retry err ->", count);
                setTimeout(async () => await this.retry(asyncMethod, count + 1), 3000);
            }
            return err;
        }

    }
}

export default StockService;