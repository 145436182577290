import { NOTFOUND } from "dns";
import IImage from "../../Model/Interface/IImage";
import IProductCategory from "../../Model/Interface/IProductCategory";
import IService from "../Interface/IService";

const axios = require('axios');

export class StorageService implements IService {

    public static async addImage(file: File, id: string) {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/file/add/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log(response)

            if (response.data) {
                console.log(response.data)
                return response.data as IImage
            }
            else {
                console.info('error add', response)
                throw new Error(response)
            }
        } catch (error: any) {

            console.info('error axios', error.message);
            alert(error.message)
        }
        //TODO: Integrar na api
    }
    public static async deleteImage(fileName: string) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/file/delete?fileName=${fileName}`)
        return response.data as boolean
    }

}
