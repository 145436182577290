

import React from 'react';
import './assets/main.css';
import Routes from './routes/index';
import { AuthProvider } from './context/Auth';
import CssBaseline from '@mui/material/CssBaseline';


export function App() { 
  return (
  <AuthProvider >
    <CssBaseline/>
    <Routes />
  </AuthProvider>
  );
}
