export const colors = {
    'dark_brown': '#715C46',
    'light_brown': '#C9B29A ',
    'salmon': '#E09F70',
    'ice': '#DED0C3',
    'pantone': '#D4C1BD',
    'white': '#FFF',
    'black': '#000',
    'gray': '#BFBFBF',
  }
  
  export const menuBody = {
    backgroundColors: colors.ice,
    padding: "10px",
    width: "25%"
  }
  
  
  
  export const titleStyled = {
    color: colors.dark_brown,
    fontSize: "20px",
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    fontWeight: "bold"
  }
  
  export const backGroundStyled = {
    backgroundColor: "white",
    flex: 1,
    borderRight: "0.5px solid rgb(230,227,227)",
  }
  
  export const top = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
  
  export const logo = (width:number) => {
    if (width < 1000) {
      return (
        {
          height: "auto",
          justifyContent: "start",
          width: "150px"
        }
      )
    }
    else {
      return (
        {
          height: "auto",
          justifyContent: "start",
          width:"250px"
        }
      )
    }
  }
  
  export const icon = (width: number) => {
    if (width > 1024) {
      return (
        {
          fontSize: "30px",
          color: colors.salmon,
          height: "25px",
          borderColor: colors.dark_brown,
        }
      )
    }
    else {
      return (
        {
          fontSize: "20px",
          color: colors.salmon,
          height: "12px",
          borderColor: colors.dark_brown,
        }
      )
    }
  
  }
  
  export const ulStyled = {
    listStyle: "none",
    margin: '25px 25px 25px 25px',
    padding: 0,
    width: "auto",
  }
  
  export const liStyled = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    cursor: "pointer"
  }
  
  export const spanStyled = {
    fontSize: "18px",
    color: colors.dark_brown,
    marginLeft: "10px"
  }
  
  export const spanStyledBySize = (width: number) => {
    if (width > 1024) {
      return (
        {
          fontSize: "20px",
          color: colors.dark_brown,
          marginLeft: "10px"
        }
      )
    }
    else {
      return (
        {
          fontSize: "11px",
          color: colors.dark_brown,
          marginLeft: "1px"
        }
      )
    }
  }
  
  import { makeStyles } from "@mui/styles"
  import { borderRadius } from "@mui/system"
  
  
  export const button = {
    display: "inline-block",
    padding: "15px 25px",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: "#fff",
    backgroundColor: colors.dark_brown,
    border: "none",
    borderRadius: "15px",
    boxShadow: "1px 3px #999"
    }
  
  export const buttonHover = {
    display: "inline-block",
    padding: "15px 25px",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: "#fff",
    backgroundColor: colors.light_brown,
    border: "none",
    borderRadius: "15px",
    boxShadow: "1px 3px #999",
    "active": {
      backgroundColor: colors.light_brown,
      boxShadow: "1px 5px #666",
      transform: "translateY(4px)"
    }
  }
  
  export const buttonActive = {
    backgroundColor: colors.light_brown,
    display: "inline-block",
    padding: "15px 25px",
    fontSize: "16px",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: "#fff",
    border: "none",
    borderRadius: "15px",
    boxShadow: "1px 5px #666",
    transform: "translateY(4px)"
  }
  
  export const useOutlinedInputStyles = makeStyles({
    root: {
      "&.MuiInputBase-root": {
        "& fieldset": {
          borderColor: colors.dark_brown,
        }
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.dark_brown,
        '& .MuiOutlinedInput-': {
          borderColor: colors.dark_brown,
        }
      },
        "&[type='text']":{
          "&:focus":{
            borderColor: `${colors.dark_brown} !important`,
            outline:"0 !important",
            outlineOffset:"0 !important"
          }}
      }
    });

    export const cardDiv = {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        hover: {
          boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
        },
        borderRadius:"5px",
      }