import React, { useState } from 'react';

import SignedRoutes from './SignedRoutes';
import OtherRoutes from './Routes';
import { useAuth } from '../context/Auth';

const Routes: React.FC = () => {
    const { signed, checkLogin } = useAuth();

    return signed || checkLogin() ?  <SignedRoutes /> : <OtherRoutes /> ;
};

export default Routes;