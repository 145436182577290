import React, { useState } from "react";
import { button, buttonActive, buttonHover, spanStyledBySize, titleStyled } from '../../globalStyle';

import {v4 as uuid} from 'uuid';
import { SecurityService } from "../../Service";
import { User } from "../../Model/User";
import { validateCPF } from "../../Utils/Validations";
import { Person } from "../../Model/Person";

export function UserForm(props: {
    data: User,
    isUpdate: boolean
}) {
    const [buttonStyle, setButtonStyle] = useState<{}>(button);

    const handleMouseEnter = () => {
        setButtonStyle(buttonHover)
    };

    const handleMouseLeave = () => {
        setButtonStyle(button)
    };

    const handleMouseClick = () => {
        setButtonStyle(buttonActive)
    };

    const [user, setUser] = useState<User>(props.data);
    const [person, setPerson] = useState<Person>(props.data.person || null)
    const [name, setName] = useState(props.data.name)
    const [email, setEmail] = useState(props.data.email)
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [cpf, setCpf] =  useState(props.data.person && props.data.person.cpf ? props.data.person.cpf : '0')
    const [gender, setGender] =  useState(props.data.person && props.data.person.cpf  ? props.data.person.gender : 'O')
    const [accountType, setAccountType] =  useState(props.data.accountType)


    async function handleOnSubmit(event: any) {
        checkData()
        user.id = uuid()
        user.name = name;
        user.email = email;
        user.password = confirmPass;
        user.oAuthIdentities = {};
        user.accountType = accountType
        user.person.id = uuid() 
        user.person.name = name;
        user.person.age = 0;
        user.person.cpf = cpf;
        user.person.gender = gender;
        setUser(user);

        try{
            await SecurityService.createUser(user)
            window.location.reload();
        }
        catch (err:any){
            
            if(err && err.response && err.response.data && err.response.data.result && err.response.data.result.message){
              alert(err.response.data.result.message)
            }
        }   
    }

    function checkData() {
        if(password != confirmPass) {
            alert("Senhas divergem")
        }

        if(name != '' && email != ''  ) {
            return true
        }
        else{
            alert("preencha todos os campos")
        }
        return false
    }

    function handleCpf(value : string){
        if(validateCPF(value) ){
            setCpf(value);
        }
        else
        {
            alert('cpf invalido - Somente Números')
        }
    }

    return (
        <div className="container ">
            <div className="grid grid-cols-4 gap-2 w-7/12 mx-auto">
                <div className="col-span-4 mx-auto my-8">
                <span style={titleStyled}> Cadastrar Usuário</span>
                </div>
                <div className="col-span-4 ml-4 mr-1">
                    <label htmlFor="name" style={spanStyledBySize(window.screen.width)}>Nome
                        <input
                            type="text"
                            id="name"
                            placeholder={"John"}
                            aria-describedby="John"
                            onChange={(e) => { setName(e.target.value) }}
                            className="my-3 py-2 w-full border-2 rounded-lg text-center"
                            value={name}
                        />
                    </label>
                </div>
                <div className="col-span-4 ml-4 mr-1">
                    <label htmlFor="cpf" style={spanStyledBySize(window.screen.width)}>CPF 
                        <input
                            id="name"
                            placeholder={"0"}
                            aria-describedby="0"
                            onChange={(e) => { setCpf(e.target.value)  }}
                            onBlur={(e) => { handleCpf(e.target.value) }}
                            className="my-3 py-2 w-full border-2 rounded-lg text-center"
                            value={cpf}
                        />
                    </label>
                </div>
                <div className="col-span-4 lg:col-span-2 w-full">
                    <label htmlFor="gender" style={spanStyledBySize(window.screen.width)}>Gênero
                    <select 
                        name="gender" 
                        id="gender-1" 
                        style={spanStyledBySize(window.screen.width)}
                        className="my-3 py-1 w-full border-2 rounded-lg text-center">
                         <option key={`1`}
                                onSelect={() => setGender('M')}>
                                Masculino
                            </option> 
                            <option key={`2`}
                                onSelect={() => setGender('F')}>
                                Feminino
                            </option> 
                            <option key={`3`}
                                onSelect={() => setGender('O')}>
                                Não Binário
                            </option> 
                    </select>
                    </label>
                </div>
                <div className="col-span-4 lg:col-span-2 w-full">
                    <label htmlFor="accountType" style={spanStyledBySize(window.screen.width)}>
                        Tipo de Conta
                    <select 
                        name="categories" 
                        id="categories" 
                        style={spanStyledBySize(window.screen.width)}
                        className="my-3 py-1 w-full border-2 rounded-lg text-center">
                         <option key={`1`}
                                onSelect={() => setAccountType('WORKER')}
                                >
                                Comum
                            </option> 
                            <option key={`2`}
                                onSelect={() => setGender('ADMIN')}
                                >
                                Administrador
                            </option> 
                            <option key={`3`}
                                onSelect={() => setGender('MOBILE')}
                                >
                                Mobile
                            </option> 
                    </select>
                    </label>
                </div>
                <div className="col-span-4 ml-4 mr-1">
                    <label htmlFor="mail" style={spanStyledBySize(window.screen.width)}>
                        E-mail
                        <input
                            type="email"
                            id="mail"
                            placeholder={"mail@mail.com"}
                            aria-describedby="e-mail"
                            onChange={(e) => { setEmail(e.target.value) }}
                            className="my-3 py-2 w-full border-2 rounded-lg text-center"
                            value={email}
                        />
                    </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="password" style={spanStyledBySize(window.screen.width)}>
                        Senha
                        <input
                            type="password"
                            id="password"
                            placeholder={"*********"}
                            aria-describedby="senha"
                            onChange={(e) => { setPassword(e.target.value) }}
                            className="mx-4 my-3 py-2 w-11/12 border-2 rounded-lg text-center"
                            value={password}
                        />
                    </label>
                </div>
                <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="confirm" style={spanStyledBySize(window.screen.width)}>
                        Confirmar Senha
                        <input
                            type="password"
                            id="confirm"
                            aria-describedby="senha"
                            onChange={(e) => { setConfirmPass(e.target.value) }}
                            className="mx-4 my-3 py-2 w-11/12 border-2 rounded-lg text-center"
                            value={confirmPass}
                        />
                    </label>
                </div>
                <div className="col-span-4 mx-auto">
                    <button
                        style={buttonStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onMouseDown={handleMouseClick}
                        onMouseUp={handleMouseEnter}
                        onClick={(e) => handleOnSubmit(e)}
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </div>
    )
}